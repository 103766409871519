.loading-screen {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000; /* High z-index to cover everything else */
  }
.textWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 4rem;
    overflow-y: hidden;

}
.text{
    font-size: 2rem;
    animation: show;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
  @keyframes show {
    0% { transform: translateY(-4rem) }
    30% { transform: translateY(0) }
    70% { transform: translateY(0) }
    100% { transform: translateY(4rem); }
  }