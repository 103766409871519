.display {
    width: 100vw;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-top: 100px;
    margin-bottom: 100px;
}
.main-comp {
    height: 100vh;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.main-comp img {
    width: 50%;
    height: 100%;
    object-fit: cover;
}
.main-comp div {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    padding: 20px;
}
.main-comp div p {
    font-size: 1.5rem;
    font-weight: 400;
    color: black;
    text-align: center;
    padding: 20px;
}
.displayDescription{
    font-size: 1rem;
    margin-bottom: 100px;
}
.displayDescription h2{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 30px;
}
.sub-comp-header {
    display: flex;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
}
.sub-comp-header .pointerIcon{
    height: 2rem;
}

.sub-comp-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 30px;
    padding-bottom: 30px;
    height: 70vh;
}

.sub-display {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.sub-display-popup {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 0px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    padding: 10px;
}

.sub-display-popup p {
    display: none;
    color: black;
    font-size: 1rem;
    font-weight: 700;
}

.sub-display-title-wrapper {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 700;
    color: white;
    text-decoration: underline;
}

.sub-display:hover .sub-display-popup {
    height: 100%;
    opacity: 0.9;
}

.sub-display:hover .sub-display-popup p {
    display: block;
}

.sub-display:hover .sub-display-title-wrapper {
    display: none;
}

@media (max-aspect-ratio: 155/100) {
    .main-comp {
        flex-direction: column;
    }
    .main-comp img,
    .main-comp div {
        width: 100%;
        height: 50%;
    }
    .sub-comp-wrapper {
        grid-template-columns: repeat(2, 1fr);
        height: 100vh;
    }
}
@media screen and (max-width: 450px) {
    .main-comp div{
        font-size: 1.5rem;
    }
    .sub-comp-wrapper {
        grid-template-columns: repeat(1, 1fr);
        height: 200vh;
    }
    .sub-comp-header .pointerIcon{
        display: none;
    }
    .sub-comp-wrapper{
        height: 200vh;
    }
}
