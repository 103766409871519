@tailwind base;
@tailwind components;
@tailwind utilities;

/* Made by Christopher Pederson of Plasmagear
  Project Start: 2024-02-03
  Version: 1.0.0
*/

:root {
    /* Colors */

    --clr-neutral-100: #ffff;
    --clr-neutral-500: #5D5D5D;
    --clr-neutral-900: #000000;
    
    --clr-red-100: #FF6E6E;
    --clr-blue-100: #00CED1;
    --clr-blue-500: #2DB3EC;
    --clr-blue-900: #305DD0;
    --clr-green-100: #CAE755;
    --clr-yellow-100: #FFD700;
    --clr-orange-100: #FFA800;
    --clr-purple-100: #DC6CF8;

    /* Globals */

    --ff-primary: "Julius Sans One", sans-serif;

    --ff-body: var(--ff-primary);
    --ff-heading: var(--ff-primary);
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
* {
    margin: 0;
    padding: 0;
    font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
html,
body {
    height: 100%;
}
body {
    background-color: var(--clr-neutral-900);
    font-family: var(--ff-primary);
    color: var(--clr-neutral-100);
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overflow-x: hidden;
}

/* A elements get no styles*/
a,
button {
    all: unset;
    cursor: pointer;
}

/* Make images easier to work with */
img,
picture,
svg {
    max-width: 100%;
    display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}