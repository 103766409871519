#banner {
    display: flex;
    flex-direction: row;
    padding-left: 8px;
}
#softwareWindow {
    height: 100vh;
    width: auto;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    aspect-ratio: 1/1;
}

@media (max-aspect-ratio: 155/100) {
    #banner {
        flex-direction: column;
        text-align: center;
        padding-left: 0px;
    }
    #introText > div,
    #introText > h1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    #introText_iconsBox {
        justify-content: center;
    }
    #softwareWindow {
        display: none;
    }
}
